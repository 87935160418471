import React from 'react';
import PropTypes from 'prop-types';
import Helmet from 'react-helmet';
import { StaticQuery, graphql } from 'gatsby';

import logoImg from 'common/src/assets/image/favicon.png';

function SEO({ description, lang, meta, keywords, title }) {
  return (
    <StaticQuery
      query={detailsQuery}
      render={data => {
        const metaDescription =
          description || data.site.siteMetadata.description;
        const schemaTitle = title.replace('|', '-');
        const currentPath =
          typeof window !== 'undefined' ? window.location.pathname : '';
        const pathSplit = currentPath.split('/');
        const validPath = pathSplit.filter(function(item) {
          return item !== '';
        });
        const correctedPath = validPath.join('/');
        const domainName = 'http://rapidautomation.ai';
        const checkURL = `${domainName}/${correctedPath}`;
        const currentURL =
          checkURL == 'http://rapidautomation.ai/' ? domainName : checkURL;
        return (
          <>
            <Helmet
              htmlAttributes={{
                lang,
              }}
              title={title}
              titleTemplate={`%s | ${data.site.siteMetadata.title}`}
              meta={[
                {
                  name: `description`,
                  content: metaDescription,
                },
                {
                  property: `og:title`,
                  content: title,
                },
                {
                  property: `og:description`,
                  content: metaDescription,
                },
                {
                  property: `og:type`,
                  content: `website`,
                },
                {
                  name: `og:image`,
                  content: `${logoImg}`,
                },
                {
                  name: `og:url`,
                  content: 'http://rapidautomation.ai/',
                },
                {
                  name: `twitter:card`,
                  content: `summary`,
                },
                {
                  name: `twitter:creator`,
                  content: data.site.siteMetadata.author,
                },
                {
                  name: `twitter:title`,
                  content: title,
                },
                {
                  name: `twitter:description`,
                  content: metaDescription,
                },
                {
                  name: `twitter:image`,
                  content: `${logoImg}`,
                },
                {
                  name: `twitter:site`,
                  content: '@rap_ventures',
                },
              ]
                .concat(
                  keywords.length > 0
                    ? {
                        name: `keywords`,
                        content: keywords.join(`, `),
                      }
                    : []
                )
                .concat(meta)}
            />
            <Helmet>
              <script type="application/ld+json">
                {`{
                  "@context":"https://schema.org",
                  "@graph":[
                    {
                      "@type":"Organization",
                      "@id":"https://rapidautomation.ai/#organization",
                      "name":"Rapid Acceleration Partners",
                      "alternateName": "Rapid Acceleration Partners",
                      "url":"https://rapidautomation.ai/",
                      "sameAs":[
                          "https://www.facebook.com/rapventures/",
                          "https://twitter.com/rap_ventures",
                          "https://www.instagram.com/rapid_acceleration_partners/",
                          "https://www.linkedin.com/company/rapid-acceleration-partners",
                          "https://www.youtube.com/channel/UCMSXhWJFm3Vvp1Rmbfa54lw/"
                      ],
                      "logo":{
                          "@type":"ImageObject",
                          "@id":"https://rapidautomation.ai/#logo",
                          "inLanguage":"en-US",
                          "url":"https://rapidautomation.ai/static/logo-e0e6c69c38cceadc5707e9baf9adc1b2.svg",
                          "width":551,
                          "height":421,
                          "caption":"Rapid Acceleration Partners"
                      },
                      "image":{
                          "@id":"https://rapidautomation.ai/#logo"
                      }
                    },
                    {
                      "@type":"WebSite",
                      "@id":"https://rapidautomation.ai/#website",
                      "url":"https://rapidautomation.ai/",
                      "name":"Rapid Acceleration Partners",
                      "description":"Practical AI and Intelligent RPA Solutions",
                      "publisher":{
                          "@id":"https://rapidautomation.ai/#organization"
                      },
                      "inLanguage":"en-US"
                    },
                    {
                      "@type":"ImageObject",
                      "@id":"https://rapidautomation.ai/#primaryimage",
                      "inLanguage":"en-US",
                      "url":"https://rapidautomation.ai/static/logo-e0e6c69c38cceadc5707e9baf9adc1b2.svg",
                      "width":551,
                      "height":421
                    },
                    {
                      "@type":"WebPage",
                      "@id":"${currentURL}/#webpage",
                      "url":"${currentURL}",
                      "name":"${schemaTitle} - Rapid Acceleration Partners",
                      "isPartOf":{
                          "@id":"https://rapidautomation.ai/#website"
                      },
                      "primaryImageOfPage":{
                          "@id":"${currentURL}/#primaryimage"
                      },
                      "description":"${metaDescription}",
                      "inLanguage":"en-US",
                      "potentialAction":[
                          {
                            "@type":"ReadAction",
                            "target":[
                                "${currentURL}"
                            ]
                          }
                      ]
                    }
                  ]
                }`}
              </script>
            </Helmet>
          </>
        );
      }}
    />
  );
}

SEO.defaultProps = {
  lang: `en`,
  meta: [],
  keywords: [],
};

SEO.propTypes = {
  description: PropTypes.string,
  lang: PropTypes.string,
  meta: PropTypes.array,
  keywords: PropTypes.arrayOf(PropTypes.string),
  title: PropTypes.string.isRequired,
};

export default SEO;

const detailsQuery = graphql`
  query DefaultSEOQuery {
    site {
      siteMetadata {
        title
        description
        author
      }
    }
  }
`;
