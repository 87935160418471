import React from 'react';
import Box from 'common/src/components/Box';
import Container from 'common/src/components/UI/Container';
import { Link } from 'gatsby';
import PropTypes from 'prop-types';
// import { useStaticQuery, graphql } from 'gatsby';

import '../ctasection.css';

const Ctasection = ({
  name,
  button,
  box_size,
  box_size2,
  cta_size_container,
  content
}) => {
  return (
    <>
      <Container fullWidth noGutter className="cta_size_container">
        <Box className="Cta_container">
          <Box {...box_size}>
            <p>
              {content || ` Achieve Peak Operational Efficiency in Weeks in Your Hospitality
              Back Office Processes`}
             
            </p>
          </Box>
          <Box {...box_size2}>
            <Link to="/bookademo" className="btn btn_tertiary">
              <span className="btn-text">Book a Demo</span>
            </Link>
          </Box>
        </Box>
      </Container>
    </>
  );
};

Ctasection.propTypes = {
  button: PropTypes.object,
  box_size: PropTypes.object,
  box_size2: PropTypes.object,
};
Ctasection.defaultProps = {
  button: {
    type: 'button',
    fontSize: ['18px', '20px', '20px', '20px', '20px'],
    fontWeight: '600',
    color: '#fff',
    borderRadius: '4px',
    pl: '22px',
    pr: '22px',
    colors: 'primaryWithBg',
    height: `{5}`,
  },
  box_size: {
    width: [1, 1, 0.6, 0.7],
  },
  box_size2: {
    width: [1, 1, 0.4, 0.3],
  },
};

export default Ctasection;
